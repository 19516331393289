import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Amir from "./images/amir.jpg";
import AmirBg from "./images/fond_amir.png";
import Guillemets from "./images/guillemets.svg";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: "10% 10%",
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  withBg: {
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `url(${AmirBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0",
    backgroundSize: "auto 100%",
    position: "relative",
  },
  pad: {
    backgroundColor: theme.palette.background.default,
    padding: "50px 0",
    minWidth: "80%",
    minHeight: 350,
    borderRadius: 25,
  },
  padMobile: {
    padding: theme.spacing(2),
    minHeight: 0,
  },
  citPos: {
    position: "relative",
  },
  cit: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2
    )}px 45%`,
    maxWidth: "95%",
    color: "#000",
  },
  sub: {
    color: theme.palette.text.secondary,
  },
  img: {
    position: "absolute",
    left: "13%",
    minWidth: "25%",
  },
  rnd: {
    borderRadius: 25,
  },
  imgMobile: {
    textAlign: "center",
    padding: 0,
    marginBottom: theme.spacing(2),
  },
}));

const Ceo = ({citation, langKey}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid
      item
      className={clsx({
        [classes.paddingRegular]: matches,
        [classes.paddingRegularMobile]: !matches,
        [classes.withBg]: true,
      })}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <div
        className={clsx({
          [classes.img]: matches,
          [classes.imgMobile]: !matches,
        })}
      >
        <img
          src={Amir}
          height={matches ? 500 : "auto"}
          width={matches ? "auto" : "100%"}
          alt="tmp"
          className={classes.rnd}
        />
      </div>
      <Grid
        item
        className={clsx({ [classes.pad]: true, [classes.padMobile]: !matches })}
      >
        <div className={clsx(classes.citPos, { [classes.cit]: matches })}>
          <Typography variant="body1" style={{ lineHeight: "1.9em" }}>
            {citation}
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Amir NAKIB, PhD</Typography>
              <Typography variant="h6" className={classes.sub}>
                {langKey === "fr" ? "Directeur Scientifique et Technique" : "CTO & Head of AI"}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <img src={Guillemets} alt="citation" />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Ceo;
